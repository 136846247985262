import React, { Component } from "react"

import { Row, Col, Container, Accordion, Card } from "react-bootstrap"

import Video from "./video"

const windowGlobal = typeof window !== "undefined" && window

class IndividualSections extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iphone: { height: 868, width: 428 },
      imac: { height: 428, width: 624 },
    }
  }

  componentDidMount() {
    let scaleValue = 1
    let top = 100
    if (windowGlobal.innerWidth > 1199) scaleValue = 0.8
    else if (windowGlobal.innerWidth > 1024) scaleValue = 0.6
    else if (windowGlobal.innerWidth > 991) scaleValue = 0.6
    else if (windowGlobal.innerWidth > 767) {
      scaleValue = 0.9
    } else if (windowGlobal.innerWidth > 576) {
      scaleValue = 0.7
      top = 30
    } else scaleValue = 0.55
    this.setState({
      iphone: { height: 868 * scaleValue, width: 428 * scaleValue, top },

      imac: { height: 428 * scaleValue, width: 624 * scaleValue },
    })
  }

  render() {
    const {
      dataList,
      dashboard,
      selectedAccordian,
      onToggleAccordian,
      videoPlaying,
      videoId,
      id,
    } = this.props
    const { iphone, imac } = this.state
    return dashboard ? (
      <Container id={id} className="py-5 section">
        <h2 className="pb-5">Admin</h2>
        <Row noGutters>
          <Col xs="12" lg="4">
            <Accordion
              defaultActiveKey="0"
              activeKey={`${selectedAccordian.index}`}
            >
              {dataList.map((feature, index) => (
                <Card
                  key={feature._id}
                  id={feature._id}
                  className={
                    selectedAccordian.index === index ? "active" : "inactive"
                  }
                >
                  <Accordion.Toggle
                    onClick={() => {
                      if (windowGlobal.innerWidth < 992) {
                        setTimeout(() => {
                          document.getElementById(feature._id).scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          })
                        }, 300)
                      }
                      onToggleAccordian({ ...feature, index })
                    }}
                    as={Card.Header}
                    eventKey={`${index}`}
                    className="pl-0"
                  >
                    <h6>{feature.title}</h6>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${index}`}>
                    <Card.Body
                      className="pl-0 pt-0"
                      style={{
                        paddingBottom: feature.last ? "100vh" : "",
                      }}
                    >
                      <p>{feature.description}</p>
                      <ul>
                        {feature.subDescription
                          ? feature.subDescription.map(sub => {
                              return <li>{sub.title}</li>
                            })
                          : null}
                      </ul>

                      {selectedAccordian.index === index &&
                      selectedAccordian.media &&
                      windowGlobal.innerWidth < 992 ? (
                        <div className="d-block d-lg-none position-relative z-index-none z-index-lg-sticky w-100 w-lg-50">
                          <Video
                            key={selectedAccordian._id}
                            dashboard
                            source={selectedAccordian.media}
                          />
                        </div>
                      ) : null}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>
          {selectedAccordian &&
          selectedAccordian.media &&
          windowGlobal.innerWidth > 991 ? (
            <div
              style={{
                height: imac.height,
                width: imac.width,
                top: 150,
              }}
              className="d-none d-lg-block position-relative position-lg-sticky top-neg-25 z-index-none z-index-lg-sticky w-100 w-lg-66"
            >
              <Video
                key={selectedAccordian._id}
                dashboard={dashboard}
                source={selectedAccordian.media}
              />
            </div>
          ) : null}
        </Row>
      </Container>
    ) : (
      <Accordion defaultActiveKey="0" activeKey={`${selectedAccordian.index}`}>
        {dataList.map((feature, index) => (
          <Card
            key={feature._id}
            id={feature._id}
            className={
              selectedAccordian.index === index ? "active" : "inactive"
            }
          >
            <Accordion.Toggle
              onClick={() => {
                if (windowGlobal.innerWidth < 992) {
                  setTimeout(() => {
                    document.getElementById(feature._id).scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }, 300)
                }
                onToggleAccordian({ ...feature, index })
              }}
              as={Card.Header}
              eventKey={`${index}`}
              className="pl-0"
            >
              <h6>{feature.title}</h6>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${index}`}>
              <Card.Body
                className={`pl-0 pt-0 ${feature.last ? "pb-4-vh" : ""}`}
              >
                <p>{feature.description}</p>
                <ul>
                  {feature.subDescription
                    ? feature.subDescription.map(sub => {
                        return <li>{sub.title}</li>
                      })
                    : null}
                </ul>

                {selectedAccordian.index === index &&
                selectedAccordian.media &&
                windowGlobal.innerWidth < 992 &&
                videoPlaying ? (
                  <div
                    style={{
                      height: iphone.height,
                      width: iphone.width,
                      top: iphone.top,
                    }}
                    className="d-block d-lg-none position-relative position-lg-sticky top-neg-25 z-index-none z-index-lg-sticky w-100 w-lg-50"
                  >
                    <Video
                      videoId={videoId}
                      key={videoPlaying._id}
                      source={videoPlaying.media}
                    />
                  </div>
                ) : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    )
  }
}

export default IndividualSections
