import React, { Component } from "react"
import { Card } from "react-bootstrap"

class Video extends Component {
  constructor(props) {
    super(props)

    this.video = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ startAnimation: false })
    }, 1000)
  }

  componentDidUpdate(preProps, preStates) {
    const { source } = this.props
    if (preProps && preProps.source && source) {
      if (preProps.source !== source) {
        this.video.current.src = source
      }
    }
  }

  render() {
    const { dashboard, source, videoId, key } = this.props
    return source ? (
      dashboard ? (
        <Card
          key={key}
          className="video-card"
          style={{
            border: "1px solid #efefef",
            boxShadow: "2px 2px 12px 1px rgba(0,0,0,0.34)",
            // minHeight: "100%",
          }}
        >
          <video
            ref={this.video}
            autoPlay
            width="100%"
            loop
            muted
            id="admin-video-tag"
          >
            <source src={source} type="video/mp4" />
          </video>
          <div className="device-content video-shutter">
            <div className="video-shutter-container"></div>
          </div>
        </Card>
      ) : (
        <div
          key={key}
          className={`device ${
            dashboard ? "device-imac-pro" : "device-iphone-x"
          } m-auto`}
        >
          <div className="device-frame">
            <video
              className="device-content"
              ref={this.video}
              loop
              autoPlay
              id={videoId}
              muted
            >
              <source src={source} type="video/mp4" />
            </video>
            <div className="device-content video-shutter">
              <div className="video-shutter-container"></div>
            </div>
          </div>
          <div className="device-stripe"></div>
          <div className="device-header"></div>
          <div className="device-sensors"></div>
          <div className="device-btns"></div>
          <div className="device-power"></div>
          <div className="device-home"></div>
        </div>
      )
    ) : null
  }
}

export default Video
