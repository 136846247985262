import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SvgFirebase from "../SVGs/firebase"
import SvgNode from "../SVGs/node"
import SvgReact from "../SVGs/react"
import IndividualSections from "../components/IndividualSections"
import ContactForm from "../components/ContactForm"
import Video from "../components/video"
import {
  agentAppFeatures,
  customerAppFeatures,
  dashboardFeatures,
  frequentFaqs,
} from "../components/featureArrays"

const windowGlobal = typeof window !== "undefined" && window

const vh = windowGlobal.innerHeight

const IndexPage = () => {
  const [showingSection, setShowingSection] = React.useState(-1)
  const [navigationMenu, setNavigationMenu] = React.useState("")
  const [videoPlaying, setVideoPlaying] = React.useState()
  const [startPlaying, setStartPlaying] = React.useState({
    agent: false,
    customer: false,
  })
  const testRef = React.useRef(startPlaying)

  const [accordianValues, setAccordianValues] = React.useState({
    agent: { index: 0, ...agentAppFeatures[0] },
    customer: { index: 0, ...customerAppFeatures[0] },
    dashboard: { index: 0, ...dashboardFeatures[0] },
    faq: { index: 0, ...frequentFaqs[0] },
  })

  const images = useStaticQuery(graphql`
    query {
      trashLyft: file(relativePath: { eq: "trashLyft.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const startOnScroll = () => {
    const section1 =
      document.getElementById("feature_agent") &&
      document.getElementById("feature_agent").getBoundingClientRect()
    const section2 =
      document.getElementById("feature_customer") &&
      document.getElementById("feature_customer").getBoundingClientRect()
    const section3 =
      document.getElementById("feature_admin") &&
      document.getElementById("feature_admin").getBoundingClientRect()
    const section4 =
      document.getElementById("contact") &&
      document.getElementById("contact").getBoundingClientRect()

    if (section1 && section1.top <= 10 && section1.top > 10 - section1.height) {
      setNavigationMenu("feature_agent")
    } else if (
      section2 &&
      section2.top <= 10 &&
      section2.top > 10 - section2.height
    ) {
      setNavigationMenu("feature_customer")
    } else if (
      section3 &&
      section3.top <= 10 &&
      section3.top > 10 - section3.height
    ) {
      setNavigationMenu("feature_admin")
    } else if (
      section4 &&
      section4.top <= 10 &&
      section4.top > 10 - section4.height
    ) {
      setNavigationMenu("contact")
    } else {
      setNavigationMenu("")
    }

    const feature1 =
      document.getElementById("feature_agent") &&
      document.getElementById("feature_agent").getBoundingClientRect()
    const feature2 =
      document.getElementById("feature_customer") &&
      document.getElementById("feature_customer").getBoundingClientRect()
    const feature3 =
      document.getElementById("feature_admin") &&
      document.getElementById("feature_admin").getBoundingClientRect()

    const agentVideo = document.getElementById("agent-video-tag")
    const customerVideo = document.getElementById("customer-video-tag")
    const adminVideo = document.getElementById("admin-video-tag")

    if (feature1 && feature1.top < vh / 2) {
      if (showingSection !== 1) {
        if (!testRef.current.agent) {
          setVideoPlaying(accordianValues.agent)
          setStartPlaying(val => {
            testRef.current = { ...val, agent: true }
            return { ...val, agent: true }
          })
        }
        setTimeout(() => {
          agentVideo && agentVideo.play()
        }, 100)
      }
      setShowingSection(1)
    }

    if (feature2 && feature2.top < vh / 2) {
      if (showingSection !== 2) {
        if (!testRef.current.customer) {
          setVideoPlaying(accordianValues.customer)
          setStartPlaying(val => {
            testRef.current = { ...val, customer: true }
            return { ...val, customer: true }
          })
        }
        setTimeout(() => {
          customerVideo && customerVideo.play()
        }, 100)
      }
      setShowingSection(2)
    }

    if (feature3 && feature3.top < vh / 2) {
      if (showingSection !== 3) {
        adminVideo && adminVideo.play()
      }
      setShowingSection(3)
    }
  }

  React.useEffect(() => {
    // if (windowGlobal.innerWidth < 992) setVideoPlaying(agentAppFeatures[0])
    windowGlobal.addEventListener("scroll", startOnScroll, {
      passive: true,
    })
    return () => windowGlobal.removeEventListener("scroll", startOnScroll)
  }, [])

  const onToggleAccordian = (values, type) => {
    if (type === "agent" || type === "customer") setVideoPlaying(values)
    setAccordianValues({ ...accordianValues, [type]: values })
  }

  return (
    <Layout pageInfo={{ pageName: "index" }} navigationMenu={navigationMenu}>
      <SEO
        title="TrashLyft |  Tech Solution for On-demand Trash Disposal Businesses"
        description="iOS, Android app for on-demand trash disposal management"
        keywords={[`TrashLyft`, `Android`, `IOS`, `Admin Dashboard`]}
      />
      <main>
        <Container className="banner bg-primary" fluid>
          <Container>
            <Row noGutters>
              <Col
                xs="12"
                lg="6"
                className="pt-5 mt-5 pt-lg-0 mt-lg-0 d-flex justify-content-center align-items-center"
              >
                <div>
                  <h1 style={{ fontSize: 40, flex: 1 }}>
                    Tech Solution for <br />
                    On-Demand Trash Removal Businesses
                  </h1>
                  <p>
                    Mobile apps for customers to place orders, trash pickup
                    agents to receive requests, and web app for owners to manage
                    the business.
                  </p>
                </div>
              </Col>
              <Col xs="12" lg="6">
                <Img fluid={images.trashLyft.childImageSharp.fluid} />
              </Col>
            </Row>
          </Container>
        </Container>

        <section
          id="features"
          className
          style={{
            position: "relative",
          }}
        >
          {/* Agent App Features */}
          <Container className="py-5">
            <Row>
              <Col xs="12" lg="6">
                <div id="feature_agent">
                  <h2 className="pb-5">Agent</h2>
                  <IndividualSections
                    title="Agent"
                    type="agent"
                    videoId="agent-video-tag"
                    dataList={agentAppFeatures}
                    videoPlaying={videoPlaying}
                    selectedAccordian={accordianValues.agent}
                    onToggleAccordian={accordian =>
                      onToggleAccordian(accordian, "agent")
                    }
                  />
                </div>

                <div id="feature_customer" className="pt-5">
                  <h2 className="py-5">Customer</h2>
                  <IndividualSections
                    title="Customer"
                    type="customer"
                    videoId="customer-video-tag"
                    videoPlaying={videoPlaying}
                    dataList={customerAppFeatures}
                    selectedAccordian={accordianValues.customer}
                    onToggleAccordian={accordian =>
                      onToggleAccordian(accordian, "customer")
                    }
                  />
                </div>
              </Col>
              {videoPlaying &&
              videoPlaying.media &&
              windowGlobal.innerWidth > 991 ? (
                <Col
                  lg="6"
                  style={{
                    height: 868 * 0.8,
                    width: 428 * 0.8,
                    top: 100,
                    zIndex: 99,
                  }}
                  className="d-none d-lg-block position-relative position-lg-sticky top-neg-25"
                >
                  <Video
                    videoId={
                      showingSection === 2
                        ? "customer-video-tag"
                        : "agent-video-tag"
                    }
                    showingSection={showingSection}
                    key={videoPlaying._id}
                    source={videoPlaying.media}
                  />
                </Col>
              ) : null}

              {/* Customer App Features */}
            </Row>
          </Container>

          {/* Admin App Features */}
          <IndividualSections
            id="feature_admin"
            dashboard
            showingSection={showingSection}
            dataList={dashboardFeatures}
            selectedAccordian={accordianValues.dashboard}
            onToggleAccordian={accordian =>
              onToggleAccordian(accordian, "dashboard")
            }
          />
        </section>

        <Container className="py-5 mt-0 mt-lg-5">
          <Row className="py-5 mt-0 mt-lg-5">
            <Col xs="12" lg="6" className="mb-5">
              <div className="tecnhology-card">
                <h3 className="text-center">Architectural Diagram</h3>
                <hr width="75" />
                <p className="pt-4 text-center">
                  Download the architectural diagram to view the server
                  architecture and interactions of various backend services and
                  frontend apps
                </p>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/TrashLyft-Architecture.pdf?alt=media"
                  download
                  target="_blank"
                  rel="noreferrer"
                  className="custom-button d-flex align-items-center justify-content-center"
                >
                  DOWNLOAD
                </a>
              </div>
            </Col>

            <Col xs="12" lg="6" className="mb-5">
              <div className="tecnhology-card">
                <h3 className="text-center">Tech Stack</h3>
                <hr width="75" />
                <p className="pt-4 text-center">
                  The tech stack and third party services that was used to build
                  TrashLyft
                </p>
                <div className="d-flex justify-content-between w-100">
                  <div className="uni-tech-card">
                    <SvgReact className="w-50" />
                    <p className="mb-0 text-center">React</p>
                  </div>
                  <div className="uni-tech-card">
                    <SvgReact className="w-50" />
                    <p className="mb-0 text-center">React Native</p>
                  </div>
                  <div className="uni-tech-card">
                    <SvgNode className="w-50" />
                    <p className="mb-0 text-center">Node JS</p>
                  </div>
                  <div className="uni-tech-card">
                    <SvgFirebase className="w-50" />
                    <p className="mb-0 text-center">Firebase</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <section id="contact">
          <ContactForm />
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
