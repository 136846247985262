export const agentAppFeatures = [
  {
    _id: "agent_feature_1",
    title: "Onboarding & Verification",
    description:
      "The simple, intuitive onboarding process for agents to enroll on the platform.",
    // subDescription: [
    //   { title: "Basic Profile Information" },
    //   { title: "Validating Mobile number" },
    //   { title: "Uploading Verification documents" },
    //   { title: "Providing Bank Account information for transfer of earnings" },
    // ],
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-onboarding.mp4?alt=media",
  },
  {
    _id: "agent_feature_2",
    title: "Pickup Request Notification",
    description:
      "Pickup requests are automatically sent out to nearby agents, the agents receive alerts on their mobile app which displays the details of the job.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-orderaccept.mp4?alt=media",
  },
  {
    _id: "agent_feature_3",
    title: "Pickup Request Fulfillment",
    description:
      'Agents can easily update the status of the pickup request by just a swipe. They are required to take a picture of the trash they collected and draw their signature when marking the order as "completed” as a proof of request fulfillment.',
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-orderfullfillment.mp4?alt=media",
  },
  {
    _id: "agent_feature_4",
    title: "Task Management",
    description:
      "A filterable and sortable list of tasks that allows agents to manage their tasks. Tasks can be sorted by distance, filtered by type of service, status, and by period.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-ordersorting.mp4?alt=media",
  },
  {
    _id: "agent_feature_5",
    title: "Route Optimization",
    description:
      "The app displays optimized routes that should be taken by the agent for completing their daily tasks in hand.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-routeoptimization.mp4?alt=media",
  },
  {
    _id: "agent_feature_6",
    title: "Disposal/Dump-sites",
    description:
      "Displays closest disposal/dump-sites throughout the route of the agent so that the agents can easily locate them and dispose of trash on these sites.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-disposalsites.mp4?alt=media",
  },
  // {
  //   _id: "agent_feature_7",
  //   title: "Navigation Assist",
  //   description:
  //     "Navigation assist using google/apple maps to locate pickup and dumpsites.",
  // },
  {
    _id: "agent_feature_7",
    title: "Earnings & Mileage",
    description:
      "Daily/Weekly earnings and mileage reports at a single glance for agents.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-earnings.mp4?alt=media",
  },
  {
    _id: "agent_feature_8",
    title: "Rating",
    description: "Rating system for agents to rate their customers.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/agent-rating.mp4?alt=media",
  },
]

export const customerAppFeatures = [
  {
    _id: "customer_feature_1",
    title: "Onboarding",
    description:
      "Simple and intuitive onboarding and registration process for customers.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-onboarding.mp4?alt=media",
  },
  {
    _id: "customer_feature_2",
    title: "Referral Code",
    description:
      "TrashLyft has a referral code reward system to motivate customers to bring in new users and earn rewards.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-referrals.mp4?alt=media",
  },
  {
    _id: "customer_feature_3",
    title: "Trash Pickup Request",
    description:
      "The app provides an intuitive and smooth checkout flow for customers to choose the service that they need, upload photos of trash, and make payments with ease.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-order-placing.mp4?alt=media",
  },
  {
    _id: "customer_feature_4",
    title: "Live Tracking",
    description:
      "Customers can track the status of their order and location of agents in real-time.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-order-tracker.mp4?alt=media",
  },

  {
    _id: "customer_feature_5",
    title: "Order Management",
    description:
      "Using TrashLyft’s intuitive dashboard, customers can view their past and current orders without hassles.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-paymentoptions.mp4?alt=media",
  },
  {
    _id: "customer_feature_6",
    title: "Payment Options",
    description:
      "Customers can manage their payment options easily from the dashboard.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-paymentoptions.mp4?alt=media",
  },
  {
    _id: "customer_feature_7",
    title: "Pickup Addresses",
    description:
      "Customers can manage their Pickup addresses from the Address Dashboard.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-address.mp4?alt=media",
  },

  {
    _id: "customer_feature_8",
    title: "Rating",
    last: true,
    description:
      "After an order is fulfilled, customers can leave feedback of the service by rating the agent",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/customer-rating.mp4?alt=media",
  },
]

export const dashboardFeatures = [
  {
    _id: "dashboard_feature_1",
    title: "Overview",
    description:
      "The dashboard gives an overview of the business activities, location of agents, pickup locations, pending orders, the ability to assign/reassign orders to agents, modify and create new orders.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Overview.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_2",
    title: "Sales",
    description:
      "Get an overview and detailed information about your sales with just a few clicks using the sales dashboard.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Sales.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_3",
    title: "Services",
    description:
      "Create, edit, and manage various trash pickup services that your business offers.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Services.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_4",
    title: "Agents",
    description:
      "The agent dashboard allows the admin to view, manage, verify agents who have enrolled, and on the job.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Agents.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_5",
    title: "Customers",
    description:
      "View your customers, their contact information, sales statistics, and more.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Customers.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_6",
    title: "Service Areas",
    description:
      "Manage your area of operations by marking it on the map, so that only customers within the serviceable area can place requests.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Cities.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_7",
    title: "Disposal Sites",
    description:
      "Create and manage disposal/dumpsites which will be available for agents on their app.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-DisposalSites.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_8",
    title: "Business Hours & Lead Time",
    description:
      "Manage the business hours of your operations and lead time for fulfillment of service requests.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-BusinessHours.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_9",
    title: "Notification settings",
    description:
      "Manage how (SMS, email, push notifications) and when notifications should be sent to agents and customers.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Notifications.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_10",
    title: "Taxes",
    description:
      "Automatically compute taxes on checkout by adding and associating tax classes to services.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Taxes.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_11",
    title: "Commissions",
    description:
      "Automatically compute fees payable to agents for their delivery, based on duration and distance traveled for executing the pickup request.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-FeesNCard.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_12",
    title: "Coupons",
    description:
      "Run promos by creating and distribution coupon codes. Coupon codes are directly integrated with Stripe hence the usage is easily trackable.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-Coupons.mp4?alt=media",
  },
  {
    _id: "dashboard_feature_13",
    title: "App Customization",
    description:
      "Use TrashLyft’s app customization feature to customize the colors, text, and icons on the agent and customer mobile apps without redeploying your app to the App/Play stores.",
    media:
      "https://firebasestorage.googleapis.com/v0/b/thpillar-219209.appspot.com/o/Admin-AppCustomization.mp4?alt=media",
  },
]

export const frequentFaqs = [
  {
    _id: "frequent_faq_1",
    title: "What will I get for this price?",
    subDescription: [
      { value: "Agent App, Customer app in both iOS & Android Platform" },
      { value: "Administrative web app" },
      { value: "3 backend Node Js services that power the app" },
      { value: "1-year support and updates." },
    ],
  },
  {
    _id: "frequent_faq_2",
    title: "What will be the monthly recurring costs?",
    subDescription: [
      { label: "Hosting", value: "~$85/ Month (App Engine Google Cloud)" },
      { label: "Database", value: "~$60/ Month (Mongo Atlas)" },
      { label: "Email Services", value: "~$15/Month (Send Grid)" },
      { label: "Maps", value: "~$60/Month(Google Maps)" },
      { label: "App Store", value: "$99/Year" },
      { label: "PlayStore", value: "$25 (one time)" },
      { label: "Messaging", value: "$0.0075 / Text Message  (Twilio)" },
    ],
  },
  {
    _id: "frequent_faq_3",
    title: "Is the purchase refundable?",
    description:
      "You can cancel your purchase and get a refund anytime before we send you the download link for the app when we launch in Sep 2020.",
  },
  {
    _id: "frequent_faq_4",
    title: "Do you offer customization?",
    description:
      "Yes, we can completely customize the app for an additional cost.",
  },
]
