import React from "react"

const SvgFirebase = props => (
  <svg
    id="firebase-logo"
    {...props}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
  >
    <path
      d="M78.39,23.1a3.49,3.49,0,0,0-6.21,0L64.29,38,46.09,2.86A3.52,3.52,0,0,0,39.53,4L21.2,122.07,96,57.35Z"
      style={{ fill: "#ffa000" }}
    />
    <path d="M81,70.31,64.29,38,21.2,122.07Z" style={{ fill: "#f57f17" }} />
    <path
      d="M114.87,34.1a3.35,3.35,0,0,0-5.68-1.82l-88,89.8,48.69,25.6a10.15,10.15,0,0,0,9.92,0l49-25.6Z"
      style={{ fill: "#ffca28" }}
    />
  </svg>
)

export default SvgFirebase
