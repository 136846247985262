import React from "react"

const SvgNode = props => (
  <svg
    {...props}
    id="node-logo"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
  >
    <path
      d="M54.61,57,39.89,65.13A1.71,1.71,0,0,0,39,66.61v16.3a1.71,1.71,0,0,0,.89,1.48l14.72,8.15a1.87,1.87,0,0,0,1.78,0l14.72-8.15A1.71,1.71,0,0,0,72,82.91V66.61a1.71,1.71,0,0,0-.89-1.48L56.39,57a1.87,1.87,0,0,0-.89-.23,1.84,1.84,0,0,0-.89.23m75.74,14.67L127.9,73a.28.28,0,0,0-.15.24V76a.29.29,0,0,0,.15.25l2.45,1.36a.32.32,0,0,0,.3,0l2.45-1.36a.29.29,0,0,0,.15-.25V73.26a.28.28,0,0,0-.15-.24l-2.45-1.36a.3.3,0,0,0-.3,0"
      style={{
        fill: "#388e3c",
      }}
    />
    <path
      d="M110.25,39.72l-7.16-4a.71.71,0,0,0-.73,0,.72.72,0,0,0-.36.62V60.51l-6.61-3.66a1.84,1.84,0,0,0-1.78,0h0L78.89,65A1.68,1.68,0,0,0,78,66.47v16.3a1.68,1.68,0,0,0,.89,1.47L93.62,92.4a1.85,1.85,0,0,0,1.77,0l14.73-8.16a1.69,1.69,0,0,0,.88-1.47V41A1.45,1.45,0,0,0,110.25,39.72ZM101.6,79l-6.69,3.7a.83.83,0,0,1-.81,0L87.41,79a.76.76,0,0,1-.41-.67V70.92a.77.77,0,0,1,.41-.68l6.69-3.7h0a.79.79,0,0,1,.4-.1,1,1,0,0,1,.41.1l6.69,3.7a.78.78,0,0,1,.4.68v7.41A.77.77,0,0,1,101.6,79Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M54.61,57,39,82.91a1.71,1.71,0,0,0,.89,1.48l14.72,8.15a1.87,1.87,0,0,0,1.78,0L72,66.61a1.71,1.71,0,0,0-.89-1.48L56.39,57a1.87,1.87,0,0,0-.89-.23,1.84,1.84,0,0,0-.89.23"
      style={{
        fill: "#2e7d32",
      }}
    />
    <path
      d="M54.61,57,39.89,65.13A1.71,1.71,0,0,0,39,66.61L54.61,92.54a1.87,1.87,0,0,0,1.78,0l14.72-8.15A1.71,1.71,0,0,0,72,82.91L56.39,57a1.84,1.84,0,0,0-.89-.23,1.87,1.87,0,0,0-.89.23"
      style={{
        fill: "#4caf50",
      }}
    />
    <path
      d="M146.11,65l-14.72-8.15a1.84,1.84,0,0,0-1.78,0h0L114.89,65a1.72,1.72,0,0,0-.89,1.51V82.74a1.73,1.73,0,0,0,.89,1.51l14.73,8.15a1.85,1.85,0,0,0,1.77,0l6.79-3.76a.9.9,0,0,0,0-1.58L123.41,79a.79.79,0,0,1-.41-.68v-7.4a.75.75,0,0,1,.39-.66l6.71-3.71h0a.86.86,0,0,1,.41-.1.79.79,0,0,1,.4.1l6.71,3.71a.76.76,0,0,1,.39.66v6a.71.71,0,0,0,.36.62.73.73,0,0,0,.73,0l7.19-4.18a1.45,1.45,0,0,0,.72-1.25V66.51A1.72,1.72,0,0,0,146.11,65Zm-111,0L20.39,56.85a1.84,1.84,0,0,0-1.78,0h0L3.89,65A1.72,1.72,0,0,0,3,66.51V88.9a.73.73,0,0,0,.36.63.76.76,0,0,0,.73,0l7.19-4.17A1.45,1.45,0,0,0,12,84.09V70.92a.76.76,0,0,1,.39-.67l6.7-3.71h0a.89.89,0,0,1,.82,0l6.7,3.72a.75.75,0,0,1,.39.66V84.1a1.44,1.44,0,0,0,.72,1.25l7.19,4.18A.73.73,0,0,0,36,88.9V66.51A1.72,1.72,0,0,0,35.11,65Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M74.25,86.9a2.08,2.08,0,0,0-1.06.28L63.13,93a2.09,2.09,0,0,0-1.06,1.83v11.61a2.13,2.13,0,0,0,1.06,1.83l2.65,1.51a4.27,4.27,0,0,0,2.31.63c1.89,0,3-1.13,3-3.14V95.79a.29.29,0,0,0-.29-.3H69.5a.29.29,0,0,0-.3.3v11.47c0,.87-.92,1.76-2.42,1L64,106.69a.31.31,0,0,1-.15-.26V94.81a.33.33,0,0,1,.15-.28l10.06-5.8a.34.34,0,0,1,.31,0l10.07,5.8a.3.3,0,0,1,.16.28v11.62a.31.31,0,0,1-.16.27L74.4,112.52a.29.29,0,0,1-.31,0L71.52,111a.36.36,0,0,0-.25,0,5.77,5.77,0,0,1-1.51.7c-.18.06-.42.16.09.44l3.34,2a2.36,2.36,0,0,0,1.06.28,2,2,0,0,0,1-.3l10.06-5.82a2.09,2.09,0,0,0,1.06-1.83V94.82A2.16,2.16,0,0,0,85.36,93L75.3,87.18A2,2,0,0,0,74.25,86.9Z"
      style={{
        fill: "#4caf50",
      }}
    />
    <path
      d="M89.94,92.26a1.94,1.94,0,1,0,1.93,1.93A1.91,1.91,0,0,0,89.94,92.26Z"
      style={{
        fill: "#4caf50",
      }}
    />
    <path
      d="M89.92,92.57a1.62,1.62,0,0,1,1.64,1.62,1.66,1.66,0,0,1-1.64,1.64,1.63,1.63,0,0,1,0-3.26Z"
      style={{
        fill: "#4caf50",
      }}
    />
    <path
      d="M89.22,93.09v2.18h.41V94.4H90c.16,0,.2.07.23.19s.07.58.09.68h.44a2.66,2.66,0,0,1-.1-.58c0-.27-.07-.47-.36-.48a.52.52,0,0,0,.42-.54c0-.58-.51-.58-.77-.58Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M89.63,93.44H90c.11,0,.31,0,.31.3a.29.29,0,0,1-.33.31h-.33Z"
      style={{
        fill: "#4caf50",
      }}
    />
    <path
      d="M77,95.22c-2.87,0-4.58,1.22-4.58,3.24,0,2.21,1.71,2.8,4.46,3.08,3.29.33,3.55.8,3.55,1.45,0,1.13-.91,1.6-3,1.6-2.67,0-3.26-.66-3.45-2a.29.29,0,0,0-.3-.24H72.29a.29.29,0,0,0-.29.3c0,1.69.92,3.71,5.32,3.71,3.24,0,5.06-1.26,5.06-3.45s-1.46-2.76-4.56-3.17-3.44-.63-3.44-1.37S74.63,97,77,97c2.07,0,2.84.45,3.16,1.84a.27.27,0,0,0,.27.23h1.3a.29.29,0,0,0,.21-.09.22.22,0,0,0,.07-.22C81.76,96.34,80.17,95.22,77,95.22Z"
      style={{
        fill: "#4caf50",
      }}
    />
  </svg>
)

export default SvgNode
