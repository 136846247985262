import React from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Container, Spinner } from "react-bootstrap"
import swal from "sweetalert"

const InputField = ({
  label,
  errors,
  disabled,
  type,
  name,
  register,
  placeholder,
  required,
}) => {
  return type === "textarea" ? (
    <div className="position-relative mb-4">
      <label
        className="position-absolute pl-2 pt-1"
        style={{
          pointerEvents: "none",
        }}
      >
        {label}
      </label>
      <textarea
        disabled={disabled}
        className="w-100 pt-4 pl-2 input-field"
        name={name}
        style={{ minHeight: 250 }}
        placeholder={placeholder}
        ref={register({ required })}
      />
    </div>
  ) : (
    <div className="position-relative mb-4">
      <label
        className="position-absolute pl-2 pt-1"
        style={{
          pointerEvents: "none",
        }}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        className="w-100 pt-2 pl-2 input-field"
        style={{ minHeight: 100 }}
        name={name}
        type={type}
        placeholder={placeholder}
        ref={register({ required })}
      />
      {errors && errors[name] && (
        <p className="text-danger">{name} is required</p>
      )}
    </div>
  )
}

const ContactForm = () => {
  const [loader, setLoader] = React.useState(false)
  const { register, errors, handleSubmit, reset } = useForm()

  const onSubmit = async data => {
    setLoader(true)
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    let URl = process.env.GATSBY_CONTACY_URL
    let result = await fetch(URl, {
      method: "POST",
      body: formData,
      redirect: "follow",
    })
    let res = await result.json()
    setLoader(false)
    if (res && res.success) {
      swal({
        title: "Your enquiry has been sent!",
        text: "Thank you for your enquiry, we shall get back within 12 hours!",
        icon: "success",
        button: "OK",
      })
      reset()
    } else {
      swal({
        title: "Oops your enquiry couldn't be sent!",
        text:
          "Please try again later, our tech team has been notified of this issue!",
        icon: "error",
        button: "OK",
      })
    }
  }

  return (
    <Container className="py-3">
      <h2 className="pb-3">Contact for pricing </h2>
      <div className="pb-5">
        <p className="d-inline">
          Get in touch with us for discussing pricing &amp; customizations.
        </p>
        <p className="d-inline">
          <span> Send us a mail on </span>
          <a className="text-primary" href="mailto:trashlyft@7thpillar.com">
            trashlyft@7thpillar.com
          </a>
          <span> or use the form below.</span>
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" lg="6">
            <InputField
              disabled={loader}
              register={register}
              placeholder="Your full name"
              label="Name *"
              name="name"
              required
              errors={errors}
            />
          </Col>
          <Col xs="12" lg="6">
            <InputField
              disabled={loader}
              register={register}
              placeholder="Your company name"
              label="Company name"
              name="company"
            />
          </Col>

          <Col xs="12" lg="6">
            <InputField
              disabled={loader}
              register={register}
              placeholder="Your email address"
              label="Email *"
              required
              type="email"
              name="email"
              errors={errors}
            />
          </Col>

          <Col xs="12" lg="6">
            <InputField
              disabled={loader}
              register={register}
              placeholder="Your phone number"
              label="Phone"
              name="phone"
              type="tel"
            />
          </Col>

          <Col xs="12">
            <InputField
              disabled={loader}
              register={register}
              type="textarea"
              placeholder="Your message"
              label="Message"
              name="message"
            />
          </Col>
        </Row>
        <div className="text-center w-100">
          <button
            type="submit"
            disabled={loader}
            style={{ minHeight: 75, minWidth: 170 }}
            className="custom-button text-center"
          >
            {loader ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </Container>
  )
}

export default ContactForm
